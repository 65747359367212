// STYLE FOR HOME PAGE

#home {
  #ProductFeatures {
    .AnimateLines {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: dash 3s linear forwards;
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
}
